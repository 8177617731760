import { LicenseAgreement } from "@/components/licenseAgreement/LicenseAgreement";
import { Step } from "@/components/step/Step";
import { labels } from "@/utils/labels";

export type ThirdStepProps = {
  isActive: boolean;
};

export const ThirdStep = ({ isActive }: ThirdStepProps) => {
  return (
    <Step index={3} title={labels.STEP3_TITLE} isActive={isActive}>
      {isActive && <LicenseAgreement />}
    </Step>
  );
};
