import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./TableFooter.module.scss";
import { toggleBuild } from "@/features/software/softwareSlice";
import { getActiveTab, getSelectedSoftwareCube2 } from "@/utils/selectors";
import { labels } from "@/utils/labels";

export type TableFooterProps = {
  btnText?: string;
};

export const TableFooter = ({ btnText }: TableFooterProps) => {
  const dispatch = useDispatch();

  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const activeTab = useSelector(getActiveTab);

  const footerDisabled = activeTab === 2 && selectedSoftwareCube2.length === 0;

  return (
    <tfoot
      className={classNames({
        [styles.footer]: !btnText,
        [styles.footerBtnContainer]: btnText,
        [styles.disable]: footerDisabled,
      })}
    >
      <tr className={styles.row}>
        {btnText && (
          <button
            className={classNames(styles.footerBtn, {
              [styles.disable]: footerDisabled,
            })}
            onClick={() => dispatch(toggleBuild())}
          >
            {btnText ?? labels.BUTTON_TEXT}
          </button>
        )}
      </tr>
    </tfoot>
  );
};
