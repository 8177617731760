import { Title } from "@/components/title/Title";
import Head from "next/head";
import { labels } from "@/utils/labels";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { FirstStep } from "@/components/stepContent/firstStep/FirstStep";
import {
  getActiveStep,
  getSearchError,
  getSelectedRow,
  getSoftwareBuild,
  getTableError,
} from "@/utils/selectors";
import { setPrevUrl } from "@/features/path/pathSlice";
import { Layout } from "@/components/layout/Layout";
import { ErrorBanner } from "@/components/errorBanner/ErrorBanner";
import { SecondStep } from "@/components/stepContent/secondStep/SecondStep";
import { ThirdStep } from "@/components/stepContent/thirdStep/ThirdStep";
import { Paragraph } from "@/components/paragraph/Paragraph";
import { Cart } from "@/components/cart/Cart";
import { setActiveStep } from "@/features/step/stepSlice";

const Home = () => {
  const selectedRow = useSelector(getSelectedRow);
  const searchError = useSelector(getSearchError);
  const tableError = useSelector(getTableError);
  const softwareBuilded = useSelector(getSoftwareBuild);
  const activeStep = useSelector(getActiveStep);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    setCookie("userCompletedSteps", false);
    dispatch(setPrevUrl(router.asPath));
  }, []);

  useEffect(() => {
    if (!selectedRow) {
      dispatch(setActiveStep(1));
    } else if (!!selectedRow && !softwareBuilded) {
      dispatch(setActiveStep(2));
    } else if (softwareBuilded) {
      dispatch(setActiveStep(3));
    }
  }, [selectedRow, softwareBuilded]);

  return (
    <>
      <Head>
        <title>ST - SDK Builder</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Layout>
        <Title>{labels.MAIN_TITLE}</Title>
        <Paragraph containerClassName="marginBottom">
          {labels.MAIN_PARAGRAPH}
        </Paragraph>
        <ErrorBanner
          hasMarginBottom
          isVisible={
            (!!searchError && searchError.errorCode === 500) || !!tableError
          }
          hasCloseAction
          errorCode={
            (searchError && searchError.errorCode) ||
            (tableError && tableError.errorCode)
          }
        >
          {(searchError && searchError.errorDescription) ||
            (tableError && tableError.errorDescription)}
        </ErrorBanner>
        <FirstStep isActive={!selectedRow} />
        <SecondStep isActive={!!selectedRow && !softwareBuilded} />
        <ThirdStep isActive={softwareBuilded} />

        {activeStep === 2 && <Cart />}
      </Layout>
    </>
  );
};

export default Home;
