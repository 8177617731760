import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./FirstStep.module.scss";
import { getSelectedRow } from "@/utils/selectors";
import { useGetMCUSeriesQuery } from "@/pages/api/apiSlice";
import { ApiError } from "@/types/types";
import { setError } from "@/features/table/tableSlice";
import { Step } from "@/components/step/Step";
import { labels } from "@/utils/labels";
import { Spinner } from "@/components/spinner/Spinner";
import { Paragraph } from "@/components/paragraph/Paragraph";

import { MCUSerie } from "@/types/mcu";
import { generateUuid, makeCamelCase } from "@/utils/function";
import { FirstStepTable } from "./firstStepTable/FirstStepTable";
import {
  resetClickeTraceId,
  setLastTraceId,
} from "@/features/traceId/traceIdSlice";
import { setDownloadItemFileName } from "@/features/download/downloadSlice";

export type FirstStepProps = {
  isActive: boolean;
};

export const FirstStep = ({ isActive }: FirstStepProps) => {
  const selectedRow = useSelector(getSelectedRow);

  const {
    data: table,
    isLoading,
    isFetching,
    error: queryError,
  } = useGetMCUSeriesQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetClickeTraceId());
    dispatch(setDownloadItemFileName(""));
  }, []);

  useEffect(() => {
    if (isActive && table) {
      const newUuid = generateUuid();
      dispatch(setLastTraceId(newUuid));
    }
  }, [isActive, table]);

  // EXPLANATION - handle query error
  useEffect(() => {
    if (queryError) {
      if ("data" in queryError) {
        const errorDetails = queryError.data as ApiError;

        if (errorDetails.errorCode === 500) {
          dispatch(
            setError({
              errorCode: errorDetails.errorCode,
              errorDescription: errorDetails.errorDescription,
            })
          );
        }
      }
    }
  }, [queryError]);

  return (
    <Step
      index={1}
      title={labels.STEP1_TITLE}
      hasSearch={!selectedRow}
      isActive={isActive}
      hasTitleMarginBottom={!!selectedRow}
    >
      {isLoading || isFetching ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size="small" />
        </div>
      ) : (
        <>
          {!selectedRow ? (
            <>
              <Paragraph containerClassName="marginHorizontal">
                {labels.STEP1_PARAGRAPH}
              </Paragraph>

              {table &&
                table.subclasses.map((item: MCUSerie, index: number) => {
                  const { subclassDescription, series } = item;
                  return (
                    <React.Fragment key={subclassDescription + "_" + index}>
                      <FirstStepTable
                        items={series}
                        title={subclassDescription}
                        iconName={makeCamelCase(subclassDescription)}
                        hasMarginBottom
                      />
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <FirstStepTable items={[selectedRow]} />
          )}
        </>
      )}
    </Step>
  );
};
