import { useGetLicenseQuery } from "@/pages/api/apiSlice";
import styles from "./LicenseAgreement.module.scss";
import DOMPurify from "dompurify";
import { Spinner } from "../spinner/Spinner";
import { LicenseAgreementButtons } from "./components/licenseAgreementButtons/LicenseAgreementButtons";

export const LicenseAgreement = () => {
  const { data: licenseData, isFetching, isLoading } = useGetLicenseQuery();
  const sanitizedLicenseData = DOMPurify.sanitize(licenseData?.licenseText!);

  return (
    <section className={styles.container}>
      <div className={styles.licenseContainer}>
        <div className={styles.licenseBox}>
          {isFetching || isLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner size="small" />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizedLicenseData as string,
              }}
            ></div>
          )}
        </div>
        <LicenseAgreementButtons />
      </div>
    </section>
  );
};
