import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Search.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@/icon/Icon";
import { useGetSearchedValueQuery } from "@/pages/api/apiSlice";
import {
  resetAllSearch,
  resetSearch,
  setSearch,
  setInputSearchValue,
  setSearchError,
} from "@/features/search/searchSlice";
import useOutsideAlerter from "@/hooks/useClickOutside";
import { IconNames } from "@/types/iconNames";
import { ApiError } from "@/types/types";
import { getInputSearchValue, getSearchedValue } from "@/utils/selectors";
import { add } from "@/features/table/tableSlice";
import { SearchResultData } from "@/types/search";
import { labels } from "@/utils/labels";

export type SearchProps = {
  containerClassname?: string;
};

export const Search = ({ containerClassname }: SearchProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const inputSearchValue = useSelector(getInputSearchValue);

  const { currentData: searchData, error: queryError } =
    useGetSearchedValueQuery(searchValue, {
      skip: searchValue.length < 1 || error !== null,
      refetchOnMountOrArgChange: true,
    });

  // EXPLANATION - action on search value change
  useEffect(() => {
    dispatch(setInputSearchValue(searchValue));

    // search data handling
    if (searchData) {
      dispatch(setSearchError(null));
      dispatch(setSearch(searchData));
    }

    // error handling
    if (queryError) {
      if ("data" in queryError) {
        const errorDetails = queryError.data as ApiError;

        dispatch(resetSearch());

        dispatch(
          setSearchError({
            errorCode: errorDetails.errorCode,
            errorDescription: errorDetails.errorDescription,
          })
        );
      } else {
        console.error("Unrecognized error type:", queryError);
      }
    }

    if (searchValue.length === 0) dispatch(setInputSearchValue(""));
  }, [searchData, queryError, searchValue]);

  /**
   * Handle search by clicking on Enter. Check if the value enter in input/search is valid
   *
   * @param {event} x event related to the click of the Enter key on the keyboard
   *
   */
  const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter"].includes(event.key)) {
      if (!isValidSearchTerm(searchValue)) {
        setError("You can only enter alphanumeric characters");
      }
    }
  };

  /**
   * Handle search value meanwhile user type it.
   *
   * @param {event} x event related to the writing of characters within the search input field
   * @description take the input value, set the search value in RTK global state and remove any errors.
   * Check if the user writed almost 1 character.
   * If no, ensures that no received parameters is set in search state or cancels if it is.
   * If yes but the value is not valid, ensure that an error is set.
   */
  const onType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    setError(null);

    if (value.length < 1) {
      dispatch(resetAllSearch());
    }

    if (value.length >= 1 && !isValidSearchTerm(value)) {
      setError("You can only enter alphanumeric characters");
    }
  };

  /**
   * Check search value char
   *
   * @param {term} x string to be checked
   * @returns boolean. True if text is valid, no if not.
   * @description take the term and check if it is alphanumeric.
   */
  const isValidSearchTerm = (term: string): boolean => {
    return /^[A-Za-z0-9]*$/.test(term);
  };

  /**
   * Hook that handle click out of a ref
   *
   * @param {ref} x container on which the click outside is managed
   * @param {onClickOutside} fucntion to call on click out
   *
   * @description on click outside input, set the state focused a false. This allow us to managethe change of style of the input field
   */
  useOutsideAlerter({
    ref: inputRef,
    onClickOutside: () => setIsFocused(false),
  });

  return (
    <div className={classnames(styles.container, containerClassname)}>
      <div
        ref={inputRef}
        className={classnames(styles.inputWrapper, {
          [styles.focused]: isFocused,
        })}
      >
        <span className={styles.iconContainer}>
          <Icon iconName={IconNames.SEARCH} color={"var(--color-neutral-10)"} />
        </span>
        <input
          type="search"
          className={styles.input}
          value={searchValue.toUpperCase()}
          onChange={(e) => {
            onType(e);
          }}
          onKeyDown={onEnterKeyDown}
          onFocus={() => setIsFocused(true)}
          placeholder="Search part"
        />
      </div>

      {error && <ErrorParagraph error={error} />}

      {inputSearchValue.length >= 1 && !error && <SuggestionList />}
    </div>
  );
};

const ErrorParagraph = ({ error }: { error: string }) => {
  return (
    <div className={styles.lengthErrorBox}>
      <span className={styles.alertIcon}>
        <Icon
          iconName={IconNames.FULL_ALERT}
          color="#cc0000"
          width={16}
          height={16}
        />
      </span>
      <p>{error}</p>
    </div>
  );
};

const SuggestionList = () => {
  const searchedValue = useSelector(getSearchedValue);
  const inputSearchValue = useSelector(getInputSearchValue);
  const dispatch = useDispatch();

  /**
   * Function that handle click on suggestion
   *
   * @param {item} x element clicked
   *
   * @description take clicked element and add it on global state
   */
  const handleClick = (item: SearchResultData) => {
    dispatch(
      add({
        seriesId: item.seriesId,
        seriesName: item.seriesName,
        seriesDescription: item.seriesDescription,
        seriesSTInformationLink: item.seriesSTInformationLink,
      })
    );
  };

  /**
   * Function that highlight a string if contained in another string
   *
   * @param {text} x complete string
   * @param {searchTerm} x string to check if it's contained in text
   *
   */
  const highlightSubstring = (text: string, searchTerm: string) => {
    const regex = new RegExp(searchTerm, "gi");
    return text.replace(
      regex,
      `<span class="${styles.highlight}">${searchTerm}</span>`
    );
  };

  return (
    <ul className={styles.list}>
      {searchedValue ? (
        searchedValue.map((value, index) => {
          return (
            <li
              className={styles.listItem}
              key={value.rootPartNumber + "_" + index}
            >
              {value.rootPartNumber.includes(inputSearchValue) && (
                <button
                  onClick={() => handleClick(value)}
                  className={styles.listBtn}
                  dangerouslySetInnerHTML={{
                    __html: highlightSubstring(
                      value.rootPartNumber,
                      inputSearchValue
                    ),
                  }}
                />
              )}
            </li>
          );
        })
      ) : (
        <p className={styles.listItem}>
          {labels.NO_MATCH_FOR} {inputSearchValue.toUpperCase()}
        </p>
      )}
    </ul>
  );
};
