import { useDispatch, useSelector } from "react-redux";
import styles from "./WaitingDialog.module.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { DialogProps } from "@/components/dialog/Dialog";
import {
  getDownloadQuery,
  getLastTraceId,
  getSelectedRow,
  getWaitingStatus,
} from "@/utils/selectors";
import { labels } from "@/utils/labels";
import { Spinner } from "@/components/spinner/Spinner";
import {
  toggleDownload,
  toggleWaitingDialog,
} from "@/features/download/downloadSlice";
import { backHomeAndCleanState } from "@/utils/function";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Route } from "@/types/types";

export type WaitingDialogProps = Omit<DialogProps, "children"> & {
  percentage?: string;
};

export const WaitingDialog = ({ percentage, ...props }: WaitingDialogProps) => {
  const selectedRow = useSelector(getSelectedRow);
  const downloadQuery = useSelector(getDownloadQuery);
  const lastTraceId = useSelector(getLastTraceId);
  const waitingStatus = useSelector(getWaitingStatus);

  const [downloadItemPercentage, setDownloadItemPercentage] =
    useState<string>("0");

  const dispatch = useDispatch();
  const router = useRouter();

  const resetState = () => {
    backHomeAndCleanState(router, dispatch);
    dispatch(toggleDownload(false));
    dispatch(toggleWaitingDialog(false));
  };

  /**
   * @description If user doesn't click on "Build now" in waiting dialog and the percentage of completation reach 100%
   * the user will be redirected to the download page
   */
  useEffect(() => {
    const itemOnDownloadIndex = downloadQuery.findIndex(
      (item) => Object.keys(item)[0] === lastTraceId
    );

    const itemOnDownload = downloadQuery[itemOnDownloadIndex];

    if (downloadQuery[itemOnDownloadIndex]) {
      const downloadItemValues = Object.values(itemOnDownload)[0];
      downloadItemValues.percentage &&
        setDownloadItemPercentage(downloadItemValues.percentage?.toString());

      if (waitingStatus && downloadItemValues.percentage === 100) {
        toggleWaitingDialog(false);
        router.push(Route.DOWNLOAD);
      }
    }
  }, [downloadQuery]);

  return (
    <Dialog.Root
      defaultOpen={false}
      open={props.open}
      onOpenChange={props.onOpenChange}
    >
      <Dialog.Portal>
        <Dialog.Content className={styles.dialogContent}>
          <div className={styles.textBox}>
            <h1 className={styles.title}>
              {labels.BUILDING_SDK} {selectedRow?.seriesName}...
            </h1>
            <p className={styles.subtitle}>{labels.BUILD_IN_BACKGROUND}</p>
            <Spinner
              size="large"
              containerClassname={styles.spinner}
              percentage={downloadItemPercentage.toString()}
            />
            <div className={styles.actionContainer}>
              {labels.NEED_NEW_SDK}
              <button
                type="button"
                onClick={() => resetState()}
                className={styles.button}
              >
                {labels.BUILD_NOW}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
