import classNames from "classnames";
import styles from "./Paragraph.module.scss";

export type ParagraphProps = {
  children: React.ReactNode;
  containerClassName?: string;
};

export const Paragraph = ({ children, containerClassName }: ParagraphProps) => {
  return (
    <p className={classNames(styles.container, containerClassName)}>
      {children}
    </p>
  );
};
