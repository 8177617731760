import styles from "./ErrorRow.module.scss";
import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";
import Icon from "@/icon/Icon";
import { getSelectedRow } from "@/utils/selectors";
import { labels } from "@/utils/labels";
import { IconNames } from "@/types/iconNames";

export type ErrorRowProps = {
  children?: React.ReactNode;
};

export const ErrorRow = ({ children }: ErrorRowProps) => {
  const selectedRow = useSelector(getSelectedRow);

  return (
    <article className={styles.container}>
      <section
        className={classNames(styles.subtitlesContainer, {
          [styles.borderTop]: !!selectedRow,
        })}
      >
        <span className={styles.series}>{labels.SERIES}</span>
        <span className={styles.description}>Description</span>
      </section>

      <p className={styles.row}>
        <span className={styles.iconContainer}>
          <Icon
            iconName={IconNames.FULL_ALERT}
            color="#525A63"
            width={24}
            height={24}
          />
        </span>
        {children}
      </p>
    </article>
  );
};
