import styles from "./SecondStepTableTabs.module.scss";
import { Tabs } from "@radix-ui/themes";
import { useDispatch } from "react-redux";
import { resetTab, setActiveTab } from "@/features/software/softwareSlice";
import { labels } from "@/utils/labels";

export type SecondStepTableTabsProps = {
  handleChangeTab: () => void;
};

export const SecondStepTableTabs = ({
  handleChangeTab,
}: SecondStepTableTabsProps) => {
  const dispatch = useDispatch();

  return (
    <Tabs.List>
      <Tabs.Trigger
        value="cube1"
        className={`${styles.tabBtn} ${styles.cubeOneBtn}`}
        onClick={() => {
          dispatch(resetTab());
          handleChangeTab();
          dispatch(setActiveTab(1));
        }}
      >
        {labels.CUBE_1}
      </Tabs.Trigger>
      <Tabs.Trigger
        value="cube2"
        className={`${styles.tabBtn} ${styles.cubeTwoBtn}`}
        onClick={() => {
          dispatch(resetTab());
          handleChangeTab();
          dispatch(setActiveTab(2));
        }}
      >
        {labels.CUBE_2}
      </Tabs.Trigger>
    </Tabs.List>
  );
};
