import { IconNames } from "@/types/iconNames";
import styles from "./Cart.module.scss";
import Icon from "../icon/Icon";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import useOutsideAlerter from "@/hooks/useClickOutside";
import { useSelector } from "react-redux";
import { getActiveTab, getSoftwareList } from "@/utils/selectors";
import { CartContent } from "./cartContent/CartContent";

export type CartProps = {};

export const Cart = ({}: CartProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number>(0);
  const cartRef = useRef<HTMLElement>(null);
  const softwareList = useSelector(getSoftwareList);
  const activeTab = useSelector(getActiveTab);

  useEffect(() => {
    setActiveItem(
      activeTab === 1
        ? softwareList.filter(
            (item) => item.isChecked && item.cubeVersion === 1
          ).length
        : softwareList.filter(
            (item) => item.isChecked && item.cubeVersion === 2
          ).length
    );
  }, [activeTab, softwareList]);

  useOutsideAlerter({
    ref: cartRef,
    onClickOutside: () => setIsOpen(false),
  });

  return (
    <>
      <div
        className={classNames(styles.overlay, { [styles.visible]: isOpen })}
      ></div>
      <aside className={styles.wrapper} ref={cartRef}>
        <CartContent isOpen={isOpen} setIsOpen={setIsOpen} />

        <button className={styles.button} onClick={() => setIsOpen(!isOpen)}>
          <span className={styles.iconContainer}>
            <Icon
              iconName={IconNames.BOX}
              color={"var(--color-neutral-01)"}
              width={"100%"}
              height={"100%"}
            />
          </span>
          {activeItem !== 0 && (
            <span className={styles.chip}>{activeItem}</span>
          )}
        </button>
      </aside>
    </>
  );
};
