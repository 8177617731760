import styles from "./SecondStepTableRow.module.scss";
import { Software } from "@/types/software";
import { labels } from "@/utils/labels";
import React from "react";
import { TableRow } from "@/components/table/components/tableRow/TableRow";
import { TableCell } from "@/components/table/components/tableCell/TableCell";
import { CheckBox } from "@/components/checkbox/CheckBox";

export type SecondStepTableRowProps = {
  serie: Software;
  id: number;
  rowIdToString: string;
  handleOnClick: Function;
};

export const SecondStepTableRow = ({
  serie,
  id,
  rowIdToString,
  handleOnClick,
}: SecondStepTableRowProps) => {
  return (
    <React.Fragment key={serie.softwareId}>
      <TableRow>
        <TableCell size={20} hasBorderRight>
          <p
            className={`${styles.series} ${styles.text}`}
            title={serie.softwareName}
          >
            {serie.softwareName}
            {serie.isChecked ||
              (serie.suggested && (
                <span className={styles.added}>({labels.ADDED})</span>
              ))}
          </p>
        </TableCell>
        <TableCell size={50} hasBorderRight>
          <p
            className={`${styles.description} ${styles.text}`}
            title={serie.softwareDescription}
          >
            {serie.softwareDescription}
          </p>
        </TableCell>
        <TableCell size={15} hasBorderRight>
          <p
            className={`${styles.version} ${styles.text}`}
            title={serie.softwareVersion}
          >
            {serie.softwareVersion}
          </p>
        </TableCell>
        <TableCell size={15}>
          {serie.suggested ? (
            <p className={styles.btnChecked}>{labels.INCLUDED_DEFAULT}</p>
          ) : (
            <CheckBox
              id={rowIdToString}
              checked={serie.isChecked!}
              onChangeFunction={() => handleOnClick(id)}
              isEmpty
            >
              {serie.isChecked ? `${labels.REMOVE}` : `${labels.ADD_TO_SDK}`}
            </CheckBox>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
