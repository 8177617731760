import styles from "./Step.module.scss";
import classnames from "classnames";
import { Search } from "@/components/search/Search";
import { labels } from "@/utils/labels";

export type StepProps = {
  title: string;
  index: number;
  hasSearch?: boolean;
  children?: React.ReactNode;
  isActive?: boolean;
  hasTitleMarginBottom?: boolean;
  packCounter?: number | undefined;
};

export const Step = ({
  title,
  index,
  hasSearch,
  children,
  isActive,
  hasTitleMarginBottom,
  packCounter,
}: StepProps) => {
  return (
    <section
      className={classnames(styles.container, { [styles.active]: isActive })}
    >
      <div
        className={classnames(styles.titleContainer, {
          [styles.marginBottom]: hasTitleMarginBottom,
        })}
      >
        <div className={styles.titleBox}>
          <span
            className={classnames(styles.index, { [styles.active]: isActive })}
          >
            {index}
          </span>
          <h2
            className={classnames(styles.title, { [styles.active]: isActive })}
          >
            {title}
          </h2>
        </div>

        {!isActive && !!packCounter && packCounter !== 0 && (
          <p className={styles.counter}>{`${packCounter} ${
            packCounter > 1 ? `${labels.PACKS}` : `${labels.PACK}`
          } selected`}</p>
        )}
        {hasSearch && <Search containerClassname={styles.search} />}
      </div>
      {children && <>{children}</>}
    </section>
  );
};
