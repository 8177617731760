import { IconNames } from "@/types/iconNames";
import styles from "./CartContent.module.scss";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveTab,
  getSelectedRow,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getSoftwareList,
} from "@/utils/selectors";
import {
  setSoftwareList,
  toggle,
  toggleBuild,
} from "@/features/software/softwareSlice";
import { Software } from "@/types/software";
import { labels } from "@/utils/labels";
import Icon from "@/components/icon/Icon";
import { ButtonType } from "@/types/types";
import { Button } from "@/components/button/Button";

export type CartContentProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CartContent = ({ isOpen, setIsOpen }: CartContentProps) => {
  const selectedRow = useSelector(getSelectedRow);
  const dispatch = useDispatch();
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const softwareList = useSelector(getSoftwareList);
  const activeTab = useSelector(getActiveTab);

  const handleClick = (software: Software) => {
    dispatch(toggle(software));

    const updatedSeriesList =
      softwareList &&
      softwareList.map((item) => {
        if (item.softwareId === software.softwareId) {
          return { ...item, isChecked: !item.isChecked };
        }
        return item;
      });

    dispatch(setSoftwareList(updatedSeriesList));
  };

  return (
    <article
      className={classNames(styles.container, { [styles.open]: isOpen })}
    >
      <div className={styles.header}>
        <span className={styles.title}>
          {labels.SDK_SELECTION.toUpperCase()}
        </span>
        <button onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <Icon iconName={IconNames.CLOSE} color={"var(--color-neutral-01)"} />
        </button>
      </div>
      <div className={styles.mainContent}>
        <p className={styles.paragraph}>{labels.CURRENT_SELECTION}</p>
        <section className={styles.box}>
          <p className={styles.boldParagraph}>{labels.HARDWARE_SELECTION}</p>

          <p className={styles.selected}>
            {selectedRow?.seriesName.split(" ")[0]}
          </p>

          {(activeTab === 1 || selectedSoftwareCube2.length > 0) && (
            <p className={styles.boldParagraph}>{labels.ITEM_SELECTION}</p>
          )}
          <ul className={styles.list}>
            {activeTab === 1
              ? selectedSoftwareCube1.map((item: Software) => {
                  return (
                    <li className={styles.listItem} key={item.softwareId}>
                      <p className={styles.itemParagraph}>
                        {item.softwareName}
                        <span className={styles.version}>
                          {item.softwareVersion}
                        </span>
                      </p>

                      {!item.suggested && (
                        <button
                          onClick={() => handleClick(item)}
                          className={styles.closeBtn}
                        >
                          <Icon
                            iconName={IconNames.CLOSE}
                            color={"var(--color-secondary)"}
                          />
                        </button>
                      )}
                    </li>
                  );
                })
              : selectedSoftwareCube2.map((item: Software) => {
                  return (
                    <li className={styles.listItem} key={item.softwareId}>
                      {item.softwareName}
                      {item.softwareVersion}
                      <button
                        onClick={() => handleClick(item)}
                        className={styles.closeBtn}
                      >
                        <Icon
                          iconName={IconNames.CLOSE}
                          color={"var(--color-secondary)"}
                        />
                      </button>
                    </li>
                  );
                })}
          </ul>
        </section>

        <Button
          onClick={() => dispatch(toggleBuild())}
          type={ButtonType.PRIMARY}
          buttonText={labels.VALIDATE_SELECTION}
          disabled={activeTab === 2 && selectedSoftwareCube2.length === 0}
          customClassName={styles.btn}
        ></Button>
      </div>
    </article>
  );
};
