import classNames from "classnames";
import styles from "./Title.module.scss";

export type TitleProps = {
  removePaddingTop?: boolean;
  children: React.ReactNode;
};

export const Title = ({ removePaddingTop, children }: TitleProps) => {
  return <h1 className={classNames(styles.container, {
    [styles.paddingTop]: removePaddingTop,
  })}>{children}</h1>;
};
