import styles from "./Table.module.scss";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { TableHeader } from "./components/tableHeader/TableHeader";
import { getSelectedRow } from "@/utils/selectors";
import { TableFooter } from "./components/tableFooter/TableFooter";

export type TitleProps = {
  title?: string;
  iconName?: string;
  hasMarginBottom?: boolean;
  children: React.ReactNode;
  hasFooter?: boolean;
  footerBtnText?: string;
};

export const Table = ({
  title,
  iconName,
  hasMarginBottom,
  children,
  hasFooter,
  footerBtnText,
}: TitleProps) => {
  return (
    <table
      className={classNames(styles.table, {
        [styles.marginBottom]: hasMarginBottom,
      })}
    >
      {title && <TableHeader icon={iconName && iconName} headerTitle={title} />}
      <tbody
        className={classNames({
          [styles.borderBotom]: !hasFooter,
        })}
      >
        {children}
      </tbody>
      {(hasFooter || footerBtnText) && (
        <TableFooter btnText={footerBtnText && footerBtnText} />
      )}
    </table>
  );
};
